<script setup lang="ts">
import { useMounted } from '@vueuse/core';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import MlSidebarTabs from '@/components/molecules/MlSidebarTabs.vue';
import PG_ASSESSMENT_QUERY from '@/components/pages/Modules/Taxonomy/Assessment/PgAssessment.query';
import { TaxonomyAssessmentStatusEnum, type PgTaxonomyAssessmentQuery, type PgTaxonomyAssessmentQueryVariables } from '@/__generated__/types';

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const isMounted = useMounted();

const { result } = useQuery<PgTaxonomyAssessmentQuery, PgTaxonomyAssessmentQueryVariables>(PG_ASSESSMENT_QUERY, {
  id: String(route.params.assessmentId),
});

const {
  TAXONOMY_ASSESSMENT_DOCUMENTS,
  TAXONOMY_ASSESSMENT_COMPLETED,
  TAXONOMY_ASSESSMENT_DO_NO_SIGNIFICANT_HARM,
  TAXONOMY_ASSESSMENT_MINIMUM_SAFEGUARDS,
  TAXONOMY_ASSESSMENT_SUBSTANTIAL_CONTRIBUTION,
} = TaxonomyAssessmentStatusEnum;

const assessmentStatus = computed(() => result.value?.getTaxonomyAssessment.status);
const stepCompleted = computed(() => {
  switch (assessmentStatus.value) {
    case TAXONOMY_ASSESSMENT_COMPLETED: return 5;
    case TAXONOMY_ASSESSMENT_DOCUMENTS: return 4;
    case TAXONOMY_ASSESSMENT_MINIMUM_SAFEGUARDS: return 3;
    case TAXONOMY_ASSESSMENT_DO_NO_SIGNIFICANT_HARM: return 2;
    case TAXONOMY_ASSESSMENT_SUBSTANTIAL_CONTRIBUTION: return 1;
    default: return 0;
  }
});

const sidebarItems = computed(() => ([
  { label: t('Business activities'), value: 'taxonomyAssessmentBusinessActivities', isCompleted: stepCompleted.value > 0 },
  { label: t('Substantial contribution'), value: 'taxonomyAssessmentSubstantialContribution', isCompleted: stepCompleted.value > 1 },
  { label: t('Do no significant harm'), value: 'taxonomyAssessmentDoNoSignificantHarm', isCompleted: stepCompleted.value > 2 },
  { label: t('Minimum safeguards'), value: 'taxonomyAssessmentMinimumSafeguards', isCompleted: stepCompleted.value > 3 },
  { label: t('Documents (optional)'), value: 'taxonomyAssessmentDocuments', isCompleted: stepCompleted.value > 4 },
]));

const taxonomyCoreDataMinimumSafeguards = computed(() => {
  return result.value?.getOwnUser?.entity?.organization?.taxonomyCoreData?.minimumSafeguards ?? null;
});
</script>

<template>
  <div class="h-full">
    <div class="sticky top-0 full-width bg-white p-5 border-b">
      <p class="text-xl">
        {{ t('Projects') }}
        <template v-if="result?.getTaxonomyAssessment">
          / {{ result.getTaxonomyAssessment.project.name }}
        </template>
      </p>
    </div>
    <div class="flex">
      <MlSidebarTabs
        v-if="isMounted"
        class="border-r break-words"
        scrollable
        compact
        :items="sidebarItems"
        :modelValue="{ value: String(route.matched[1].name) }"
        @update:modelValue="router.push({ name: $event.value })"
      />
      <div v-if="result?.getTaxonomyAssessment" class="!px-6 !pt-12 flex flex-col justify-between gap-4 w-full h-[calc(100vh-7rem)] relative">
        <div class="absolute inset-x-0 top-0 bg-gray-200">
          <div
            class="h-1 bg-blue-600"
            :class="{
              'w-0': stepCompleted === 0,
              'w-1/5': stepCompleted === 1,
              'w-2/5': stepCompleted === 2,
              'w-3/5': stepCompleted === 3,
              'w-4/5': stepCompleted === 4,
              'w-full': stepCompleted === 5,
            }"
          />
        </div>
        <router-view
          :assessment="result.getTaxonomyAssessment"
          :taxonomyCoreDataMinimumSafeguards="taxonomyCoreDataMinimumSafeguards"
        />
      </div>
    </div>
  </div>
</template>
