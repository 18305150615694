<script setup lang="ts">
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import CheckCircleIcon from '@heroicons/vue/outline/CheckCircleIcon';
import XCircleIcon from '@heroicons/vue/outline/XCircleIcon';
import { type PgTaxonomyAssessmentQuery, TaxonomyAssessmentStatusEnum } from '@/__generated__/types';
import useUpdateTaxonomyAssessment from '@/api/mutations/Taxonomy/updateTaxonomyAssessment.mutation';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtBadge from '@/components/atoms/AtBadge/AtBadge.vue';
import type { BusinessActivity } from '../types';
import OgActivitiesTable from '../OgActivitiesTable/OgActivitiesTable.vue';
import OgValue from './OgValue.vue';

type TProps = {
  assessment: PgTaxonomyAssessmentQuery['getTaxonomyAssessment']
}

const { t } = useI18n();
const router = useRouter();
const props = defineProps<TProps>();

const { loading: isLoading, mutate: updateTaxonomyAssessment } = useUpdateTaxonomyAssessment();

const next = async () => {
  await updateTaxonomyAssessment({
    assessmentId: props.assessment._id,
    input: {
      status: TaxonomyAssessmentStatusEnum.TAXONOMY_ASSESSMENT_SUBSTANTIAL_CONTRIBUTION,
    },
  }, {
    update: (store) => {
      store.evict({ fieldName: 'getTaxonomyProjects' });
      store.evict({ fieldName: 'getTaxonomyAssessment' });
    },
  });

  router.push({
    name: 'taxonomyAssessmentSubstantialContributionActivityFit',
  });
};

const prev = () => {
  router.push({
    name: 'taxonomyAssessmentSubstantialContributionStart',
  });
};

const items = computed<BusinessActivity[]>(() => props.assessment.businessActivities.filter((ba) => ba.activityTurnover > 0));
const isAllAnswered = computed(() => items.value.every((activity) => activity.isObjectiveAnswered));
</script>

<template>
  <div>
    <p class="mb-16 font-semibold">
      {{ t('Overview of business activities and their eligibility under the EU-Taxonomy') }}
    </p>
    <p class="font-semibold">
      {{ t('Taxonomy-eligible activities') }}
    </p>
    <p class="mb-3">
      {{ t('Select the objective each activity contributes to') }}
    </p>

    <OgActivitiesTable class="mb-8" :items="items">
      <template #item-activity="item">
        {{ (item as BusinessActivity).activity.number }}: {{ t((item as BusinessActivity).activity.name) }}
      </template>
      <template #item-evaluation>
        <AtBadge type="warning">
          {{ t('Eligible') }}
        </AtBadge>
      </template>
      <template #item-objective="item">
        <OgValue
          :assessmentId="props.assessment._id"
          :businessActivity="item as BusinessActivity"
        />
      </template>
      <template #item-status="item">
        <template v-if="(item as BusinessActivity).isObjectiveAnswered">
          <CheckCircleIcon v-if="(item as BusinessActivity).objective" class="h-5 text-emerald-500" />
          <XCircleIcon v-else class="h-5 text-rose-600" />
        </template>
      </template>
    </OgActivitiesTable>
  </div>

  <div class="flex justify-between">
    <AtButton class="my-4 float-right" :disabled="isLoading" variant="outline" @click="prev">
      {{ t('Back') }}
    </AtButton>

    <AtButton class="my-4 float-right" :disabled="!isAllAnswered" :loading="isLoading" @click="next">
      {{ t('Continue') }}
    </AtButton>
  </div>
</template>
