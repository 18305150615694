<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import OgDataTable from '@/components/organisms/OgDataTable/OgDataTable.vue';
import MlEmptyStateCard from '@/components/molecules/MlEmptyStateCard.vue';
import { router } from '@/router';
import AtBadge from '@/components/atoms/AtBadge/AtBadge.vue';
import { getDPRDateString } from '@/utils/helpers/dprDates';
import { TaxonomyAssessmentStatusEnum } from '@/__generated__/types';
import MlEligibleAlignedCell from '../MlEligibleAlignedCell.vue';
import { getAssessmentStep } from '../util';
import type { TaxonomyProject } from './types';

const { t } = useI18n();

const props = defineProps<Props>();
type Props = {
  taxonomyProjects: TaxonomyProject[]
};

const hasAssessments = computed(() => !!props.taxonomyProjects.find((project) => !!project.assessments.length));
const items = computed(() => props.taxonomyProjects ?? []);

const headers = computed(() => [
  { text: t('Project'), value: 'name', sortable: true, filterable: true },
  { text: t('Assignee'), value: 'assignee', sortable: true },
  { text: t('Eligible'), value: 'eligible', sortable: true, filterable: true },
  { text: t('Aligned'), value: 'aligned', sortable: true, filterable: true },
  { text: t('Status'), value: 'status', sortable: true, filterable: true },
  { text: t('Action'), value: 'action', sortable: true, filterable: true },
]);

</script>

<template>
  <div>
    <h3 class="font-semibold leading-5 text-gray-900">
      {{ t('Recent assessments') }}
    </h3>
    <MlEmptyStateCard
      v-if="!hasAssessments"
      class="!items-start"
      :title="t('See where you last left-off')"
      :description="t('There are no recent assessments. Start your first assessment and come back at any time to complete all Taxonomy questions.')"
      :buttonText="t('Start assessment')"
      @buttonClick="router.push({ name: 'taxonomyProjects' })"
    />
    <OgDataTable
      v-else
      :headers="headers"
      :items="items"
      controlsHidden
    >
      <template #item-assignee="{ assignee }">
        {{ assignee.firstName }}
        {{ assignee.lastName }}
      </template>
      <template #item-eligible="row">
        <template v-if="row.assessments[0]">
          <MlEligibleAlignedCell :value="row.assessments[0].eligibleTurnover" :turnover="row.assessments[0].turnover" type="eligible" />
        </template>
        <template v-else>
          -
        </template>
      </template>
      <template #item-aligned="row">
        <template v-if="row.assessments[0]">
          <MlEligibleAlignedCell :value="row.assessments[0].alignedTurnover" :turnover="row.assessments[0].turnover" type="aligned" />
        </template>
        <template v-else>
          -
        </template>
      </template>
      <template #item-status="row">
        <div
          v-if="row.assessments[0]
            && (row.assessments[0].status === TaxonomyAssessmentStatusEnum.TAXONOMY_ASSESSMENT_DOCUMENTS
              || row.assessments[0].status === TaxonomyAssessmentStatusEnum.TAXONOMY_ASSESSMENT_COMPLETED)
          "
        >
          <AtBadge
            class="mr-1 py-0 text-xs font-light"
            type="success"
          >
            {{ t('Completed') }}
          </AtBadge>
          <div>
            {{ getDPRDateString(row.assessments[0].updatedAt) }}
          </div>
        </div>
        <AtBadge
          v-else-if="row.assessments[0]"
          class="mr-1  py-0 text-xs font-light"
          type="warning"
        >
          {{ t('In progress') }}
        </AtBadge>
        <AtBadge
          v-else
          class="mr-1  py-0 text-xs font-light"
          type="OPEN"
        >
          {{ t('Open') }}
        </AtBadge>
      </template>
      <template #item-action="row">
        <router-link
          v-if="row.assessments[0]
            && (row.assessments[0].status === TaxonomyAssessmentStatusEnum.TAXONOMY_ASSESSMENT_DOCUMENTS
              || row.assessments[0].status === TaxonomyAssessmentStatusEnum.TAXONOMY_ASSESSMENT_COMPLETED)
          "
          :to="{ name: 'taxonomyResults', params: { projectId: row._id, assessmentId: row.assessments[0]._id } }"
          class="underline-offset-2 text-primary hover:underline"
        >
          {{ t('View result') }}
        </router-link>
        <router-link
          v-else-if="row.assessments[0]"
          :to="{ name: getAssessmentStep(row.assessments[0].status), params: { projectId: row._id, assessmentId: row.assessments[0]._id } }"
          class="underline-offset-2 text-primary hover:underline"
        >
          {{ t('Continue') }}
        </router-link>
        <router-link
          v-else
          :to="{ name: 'taxonomyAssessmentCreate', params: { projectId: row._id } }"
          class="underline-offset-2 text-primary hover:underline"
        >
          {{ t('Start') }}
        </router-link>
      </template>
    </OgDataTable>
  </div>
</template>
