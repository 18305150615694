<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import useUpdateTaxonomyAssessment from '@/api/mutations/Taxonomy/updateTaxonomyAssessment.mutation';
import useUpdateTaxonomyAssessmentBusinessActivity from '@/api/mutations/Taxonomy/updateTaxonomyAssessmentBusinessActivity.mutation';
import { TaxonomyAssessmentStatusEnum, type PgTaxonomyAssessmentQuery } from '@/__generated__/types';
import OgAssessmentFinishingProgress from '../OgAssessmentFinishingProgress.vue';

const { t } = useI18n();
const router = useRouter();

const props = defineProps<Props>();
type Props = {
  assessment: PgTaxonomyAssessmentQuery['getTaxonomyAssessment']
  taxonomyCoreDataMinimumSafeguards: boolean | null;
}

const { mutate: updateTaxonomyAssessment } = useUpdateTaxonomyAssessment();
const { mutate: updateTaxonomyAssessmentBusinessActivity } = useUpdateTaxonomyAssessmentBusinessActivity();

const next = async () => {
  const isAssessmentCompleted = !props.assessment.businessActivities.some(
    (activity) => {
      const { circular, adaptation, mitigation, pollution, biodiversity, water } = activity.doesNotHarm;
      return circular && adaptation && pollution && biodiversity && water && mitigation;
    },
  );

  if (isAssessmentCompleted) {
    await updateTaxonomyAssessment({
      assessmentId: props.assessment._id,
      input: {
        status: TaxonomyAssessmentStatusEnum.TAXONOMY_ASSESSMENT_COMPLETED,
      },
    }, { update: (store) => { store.evict({ fieldName: 'getTaxonomyProjects' }); store.evict({ fieldName: 'getTaxonomyAssessment' }); } });
    router.push({ name: 'taxonomyAssessmentDocuments' });
  } else if (props.taxonomyCoreDataMinimumSafeguards) {
    const businessActivities = props.assessment.businessActivities
      .filter((businessActivity) => (['circular', 'adaptation', 'mitigation', 'pollution', 'biodiversity', 'water'] as const).every((key) => !!businessActivity.doesNotHarm[key]));

    for await (const businessActivity of businessActivities) {
      await updateTaxonomyAssessmentBusinessActivity({
        assessmentId: props.assessment._id,
        businessActivityId: businessActivity._id,
        input: {
          minimumStandardsRespected: true,
          minimumStandardsRespectedComment: null,
          minimumStandardsRespectedFileId: null,
        },
      });
    }

    await updateTaxonomyAssessment({
      assessmentId: props.assessment._id,
      input: {
        status: TaxonomyAssessmentStatusEnum.TAXONOMY_ASSESSMENT_COMPLETED,
      },
    }, {
      update: (store) => {
        store.evict({ fieldName: 'getTaxonomyProjects' });
        store.evict({ fieldName: 'getTaxonomyAssessment' });
      },
    });

    router.push({
      name: 'taxonomyAssessmentDocuments',
    });
  } else {
    router.push({ name: 'taxonomyAssessmentMinimumSafeguardsStart' });
  }
};

const prev = () => {
  router.push({ name: 'taxonomyAssessmentDoNoSignificantHarmChecklist' });
};

</script>

<template>
  <div class="m-auto">
    <OgAssessmentFinishingProgress :x="3" :y="5" />
  </div>

  <div class="flex justify-between w-full">
    <AtButton class="my-4 float-right" variant="outline" @click="prev">
      {{ t('Back') }}
    </AtButton>

    <AtButton class="my-4 float-right" @click="next">
      {{ t('Continue') }}
    </AtButton>
  </div>
</template>
