<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useQuery } from '@vue/apollo-composable';
import { PencilIcon, TrashIcon } from '@heroicons/vue/outline';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import OgDataTable from '@/components/organisms/OgDataTable/OgDataTable.vue';
import { TaxonomyAssessmentStatusEnum, type PgTaxonomyProjectsQuery, UserRole } from '@/__generated__/types';
import MlEmptyStateCard from '@/components/molecules/MlEmptyStateCard.vue';
import folderImg from '@/assets/folder.svg';
import { getDPRDateString } from '@/utils/helpers/dprDates';
import AtBadge from '@/components/atoms/AtBadge/AtBadge.vue';
import AtIconButton from '@/components/atoms/AtIconButton.vue';
import OgActionPanel from '@/components/organisms/OgActionPanel/OgActionPanel.vue';
import MlTabs from '@/components/molecules/MlTabs/MlTabs.vue';
import type { TDataTableItem } from '@/components/organisms/OgDataTable/types';
import MlEligibleAlignedCell from '../MlEligibleAlignedCell.vue';
import { getAssessmentStep } from '../util';
import OgCoreDataDialog from './OgCoreDataDialog/OgCoreDataDialog.vue';
import PG_PROJECTS_QUERY from './PgProjects.query';
import OgActivityTable from './OgActivityTable.vue';
import OgDocumentsTable from './OgDocumentsTable.vue';
import MlTaxonomyProjectModal from './MlTaxonomyProjectModal.vue';
import { useDeleteProject } from './useDeleteProject';
import type { TaxonomyProject } from './types';
// import OgForwardAssessmentModal from './OgForwardAssessmentModal.vue';

const { t } = useI18n();

const { result, loading } = useQuery<PgTaxonomyProjectsQuery>(PG_PROJECTS_QUERY);
const { deleteProject } = useDeleteProject();

const items = computed(() => result.value?.getTaxonomyProjects ?? []);
const isTaxonomyProjectModalShown = ref(false);
const isCoreDataDialogShown = ref(false);
// const isForwardAssessmentModalShown = ref(false);

const checkedItems = ref<TDataTableItem[]>([]);
const updateCheckedItems = (_items: TDataTableItem[]) => {
  checkedItems.value = _items;
};

const modalSelectedProject = ref<TaxonomyProject>();

const selectedProject = ref<typeof items.value[number] | null | undefined>(null);
watch(items, () => {
  if (selectedProject.value) {
    selectedProject.value = items.value.find((item) => item._id === selectedProject.value?._id);
  }
});

const tabs = computed(() => ([
  { name: t('Activities'), value: 'activities' },
  { name: t('Documents'), value: 'documents' },
]));
const selectedTab = ref(tabs.value[0]);

const headers = computed(() => [
  { text: t('Project'), value: 'name', sortable: true, filterable: true },
  { text: t('Assignee'), value: 'assignee', sortable: true },
  { text: t('Eligible'), value: 'eligible', sortable: true, filterable: true },
  { text: t('Aligned'), value: 'aligned', sortable: true, filterable: true },
  { text: t('Status'), value: 'status', sortable: true, filterable: true },
  { text: t('Action'), value: 'action', sortable: true, filterable: true },
  { text: t(''), value: 'crud', sortable: true, filterable: true },
]);

const buttons = computed(() => ({
  primary: [{
  //   text: t('Forward'),
  //   disabled: !checkedItems.value.length,
  //   onClick: () => {
  //     isForwardAssessmentModalShown.value = true;
  //   },
  // }, {
  //   text: t('Remind'),
  //   disabled: true,
  //   onClick: () => {
  //     isTaxonomyProjectModalShown.value = true;
  //   },
  // }, {
    text: t('Add'),
    onClick: () => {
      isTaxonomyProjectModalShown.value = true;
    },
  }],
}));

function editProject(project: TaxonomyProject) {
  modalSelectedProject.value = project;
  isTaxonomyProjectModalShown.value = true;
}

function removeProject(project: TaxonomyProject) {
  modalSelectedProject.value = project;
  deleteProject(project._id);
}

function handleCoreDataClick() {
  isCoreDataDialogShown.value = true;
}

function handleCoreDataDialogClose() {
  isCoreDataDialogShown.value = false;
}
</script>

<template>
  <AtButton type="button" variant="outline" @click="handleCoreDataClick">
    {{ t('Core data') }}
  </AtButton>
  <MlTaxonomyProjectModal
    :isShown="isTaxonomyProjectModalShown"
    :project="modalSelectedProject"
    @close="isTaxonomyProjectModalShown = false; modalSelectedProject = undefined"
  />
  <OgCoreDataDialog v-if="isCoreDataDialogShown" @close="handleCoreDataDialogClose" />
  <!-- <OgForwardAssessmentModal
    :isShown="isForwardAssessmentModalShown"
    :projects="(checkedItems as TaxonomyProject[])"
    @close="isForwardAssessmentModalShown = false;"
  /> -->
  <MlEmptyStateCard
    v-if="!items.length && !loading"
    :title="t('No projects yet')"
    :description="t('Create your first project to calculate your Taxonomy alignment. A project can be a business unit, site, asset, business entity or other.')"
    :buttonText="t('Create first project')"
    @buttonClick="isTaxonomyProjectModalShown = !isTaxonomyProjectModalShown"
  >
    <img
      class="mb-7"
      :src="folderImg"
      alt="Graph"
    >
  </MlEmptyStateCard>
  <OgDataTable
    v-else
    :headers="headers"
    :items="items"
    :buttons="buttons"
    :checkedItems="checkedItems"
    :showMultiSelect="false"
    bodyRowClassName="group"
    @updateCheckedItems="updateCheckedItems"
  >
    <template #item-name="row">
      <div class="cursor-pointer" @click.stop="selectedProject = row">
        {{ row.name }}
      </div>
    </template>
    <template #item-assignee="row">
      <div class="cursor-pointer" @click.stop="selectedProject = row">
        {{ row.assignee.firstName }}
        {{ row.assignee.lastName }}
      </div>
    </template>
    <template #item-eligible="row">
      <div class="cursor-pointer" @click.stop="selectedProject = row">
        <template v-if="row.assessments[0]">
          <MlEligibleAlignedCell
            :value="row.assessments[0].eligibleTurnover"
            :turnover="row.assessments[0].turnover"
            type="eligible"
          />
        </template>
        <template v-else>
          -
        </template>
      </div>
    </template>
    <template #item-aligned="row">
      <div class="cursor-pointer" @click.stop="selectedProject = row">
        <template v-if="row.assessments[0]">
          <MlEligibleAlignedCell
            :value="row.assessments[0].alignedTurnover"
            :turnover="row.assessments[0].turnover"
            type="aligned"
          />
        </template>
        <template v-else>
          -
        </template>
      </div>
    </template>
    <template #item-status="row">
      <div
        class="cursor-pointer"
        @click.stop="selectedProject = row"
      >
        <div
          v-if="row.assessments[0]
            && (row.assessments[0].status === TaxonomyAssessmentStatusEnum.TAXONOMY_ASSESSMENT_DOCUMENTS
              || row.assessments[0].status === TaxonomyAssessmentStatusEnum.TAXONOMY_ASSESSMENT_COMPLETED)
          "
        >
          <AtBadge
            class="mr-1  py-0 text-xs font-light"
            type="success"
          >
            {{ t('Completed') }}
          </AtBadge>
          <div>
            {{ getDPRDateString(row.assessments[0].updatedAt) }}
          </div>
        </div>
        <AtBadge
          v-else-if="row.assessments[0]"
          class="mr-1  py-0 text-xs font-light"
          type="warning"
        >
          {{ t('In progress') }}
        </AtBadge>
        <AtBadge
          v-else
          class="mr-1  py-0 text-xs font-light"
          type="OPEN"
        >
          {{ t('Open') }}
        </AtBadge>
      </div>
    </template>
    <template #item-action="row">
      <router-link
        v-if="row.assessments[0]
          && (row.assessments[0].status === TaxonomyAssessmentStatusEnum.TAXONOMY_ASSESSMENT_DOCUMENTS
            || row.assessments[0].status === TaxonomyAssessmentStatusEnum.TAXONOMY_ASSESSMENT_COMPLETED)
        "
        :to="{ name: 'taxonomyResults', params: { projectId: row._id, assessmentId: row.assessments[0]._id } }"
        class="underline-offset-2 text-primary hover:underline"
      >
        {{ t('View result') }}
      </router-link>
      <router-link
        v-else-if="row.assessments[0]"
        :to="{ name: getAssessmentStep(row.assessments[0].status), params: { projectId: row._id, assessmentId: row.assessments[0]._id } }"
        class="underline-offset-2 text-primary hover:underline"
      >
        {{ t('Continue') }}
      </router-link>
      <router-link
        v-else
        :to="{ name: 'taxonomyAssessmentCreate', params: { projectId: row._id } }"
        class="underline-offset-2 text-primary hover:underline"
      >
        {{ t('Start') }}
      </router-link>
    </template>s
    <template #item-crud="row">
      <span v-if="true" class="grid grid-cols-2 place-items-center gap-x-2 justify-self-end">
        <AtIconButton
          :icon="PencilIcon"
          class="w-7 text-gray-400 invisible group-hover:visible"
          :title="t('Edit')"
          @click.stop="editProject(row)"
        />

        <AtIconButton
          v-rolefrom="UserRole.ADMIN"
          :icon="TrashIcon"
          class="w-7 text-gray-400 invisible group-hover:visible"
          :title="t('Delete')"
          @click.stop="removeProject(row)"
        />
      </span>
    </template>
  </OgDataTable>
  <OgActionPanel
    v-if="selectedProject"
    :isOpened="selectedProject !== null"
    hasPaddingX
    :description="t('View details of your assessment and supporting documentation.')"
    is4xlPanel
    @closePanel="selectedProject = null"
  >
    <template #title>
      {{ selectedProject.name }}
    </template>
    <MlTabs
      v-model="selectedTab"
      class="mt-5"
      :tabs="tabs"
      isEdgeToEdge
    />

    <OgActivityTable
      v-if="selectedTab.value === 'activities'"
      :project="selectedProject"
    />
    <OgDocumentsTable
      v-else-if="selectedTab.value === 'documents'"
      :project="selectedProject"
    />
  </OgActionPanel>
</template>
