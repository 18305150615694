<script setup lang="ts">
import { computed, provide, ref, watch } from 'vue';
import { useGlobalQueryLoading } from '@vue/apollo-composable';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { SpeedInsights } from '@vercel/speed-insights/vue';
import OgFooter from '@/components/organisms/OgFooter.vue';
import AtProgress from '@/components/atoms/AtProgress/AtProgress.vue';
import { getActiveEntity, setActiveEntity } from '@/utils/services/activeEntity';
import useBreakpoints from '@/utils/composables/useBreakpoints';
import MlImpersonateBar from '@/components/molecules/MlImpersonateBar.vue';
import MlRestrictionsBar from '@/components/molecules/MlRestrictionsBar.vue';
import switchTranslation from '@/utils/helpers/switchTranslation';
import { currentUser, isGetStartedCompleted } from '@/utils/composables/useCurrentUser/useCurrentUser';
import type { UserLanguageEnum } from '@/__generated__/types';
import MlNotificationBar from './components/molecules/MlNotificationBar.vue';
import OgDrawer from './components/organisms/OgDrawer.vue';
import useConfirmViaDialog from './utils/composables/useConfirmViaDialog';
import OgPageNav from './components/organisms/OgPageNav/OgPageNav.vue';
import OgConfirmationDialog from './components/organisms/OgConfirmationDialog.vue';
import OgFetchCategoryTranslations from './components/organisms/OgFetchCategoryTranslations.vue';
import ResizablePanelGroup from './components/ui/resizable/ResizablePanelGroup.vue';
import { ResizablePanel } from './components/ui/resizable';
import ResizableHandle from './components/ui/resizable/ResizableHandle.vue';

const { t, locale } = useI18n();
const route = useRoute();
const router = useRouter();
const loading = useGlobalQueryLoading();
const { dialogData, confirm, isRevealed } = useConfirmViaDialog();
const { lg } = useBreakpoints();
const isOnboardingPath = (routePath: string) => !!routePath.match(/\/onboarding.*/);
const isGetStartedPath = (routePath: string) => !!routePath.match(/\/get-started.*/);
const isLoginPath = computed(() => !!route.path.match(/^\/login\/.*/)
  || !!route.path.match(/^\/loginCallback/)
  || !!route.path.match(/^\/emailVerified/),
);
const isAdminPath = computed(() => !!route.path.match(/^\/admin/));
const isLogoutPath = computed(() => !!route.path.match(/^\/logout/));
const isExternalPath = computed(() => !!route.path.match(/^\/external/) || !!route.path.match(/^\/external-data-entry/));
const showImpersonateBar = ref(false);
const showRestrictionsBar = ref(false);
const isSidePanelHidden = ref(false);

provide('isSidePanelHidden', isSidePanelHidden);
provide('adminMode', isAdminPath);
provide('isExternal', isExternalPath);

const isGetStartedNotificationBarOpen = ref(false);
const isGetStartedNotificationBarState = computed(
  () => !isGetStartedCompleted.value
    && !isGetStartedPath(route.fullPath)
    && !isOnboardingPath(route.fullPath),
);

watch(isGetStartedNotificationBarState, () => {
  isGetStartedNotificationBarOpen.value = isGetStartedNotificationBarState.value;
});

watch(currentUser, () => {
  if (currentUser.value?.language) {
    switchTranslation(locale, currentUser.value.language.toUpperCase() as UserLanguageEnum);
  }
  if (currentUser.value?.entity._id && currentUser.value?.entity._id !== getActiveEntity()) {
    setActiveEntity(currentUser.value?.entity._id);
  }
}, { immediate: true });

</script>

<template>
  <template v-if="isLoginPath || isLogoutPath">
    <notifications position="bottom left" />
    <router-view />
  </template>
  <template v-else-if="isAdminPath">
    <div>
      <notifications position="bottom right" />
      <router-view @showImpersonateBar="showImpersonateBar = true" />
      <MlImpersonateBar
        :shown="showImpersonateBar"
        @close="showImpersonateBar = false"
      />
    </div>
  </template>
  <template v-else-if="isExternalPath">
    <div>
      <notifications position="bottom left" />
      <router-view />
    </div>
  </template>
  <template v-if="currentUser && !isAdminPath && !isLoginPath && !isExternalPath">
    <div class="relative bg-white text-base">
      <notifications position="bottom right" />
      <AtProgress
        v-if="loading"
        foreground
      />

      <div id="topbar" />

      <MlImpersonateBar
        :shown="showImpersonateBar"
        @close="showImpersonateBar = false"
      />
      <MlRestrictionsBar
        :shown="showRestrictionsBar"
        @close="showRestrictionsBar = false"
      />
      <MlNotificationBar
        :isShown="isGetStartedNotificationBarOpen"
        :description="t('To complete your Get Started tasks, please.')"
        :buttonText="t('click here')"
        @handleClick="router.push('/get-started')"
        @close="isGetStartedNotificationBarOpen = false"
      />
      <OgConfirmationDialog
        v-bind="dialogData"
        :isRevealed="isRevealed"
        @confirm="confirm"
      />

      <div
        v-if="lg && !isOnboardingPath(route.path) && route.path !== '/loginCallback'"
        class="flex items-center justify-between p-2"
      >
        <OgDrawer>
          <template #content="{ hide }">
            <OgPageNav
              class="h-full"
              @itemClick="hide"
            />
          </template>
        </OgDrawer>
      </div>

      <div class="flex h-full min-h-screen flex-col">
        <div class="mx-auto flex w-full flex-1">
          <OgPageNav
            v-if="!lg && !isOnboardingPath(route.path) && route.path !== '/loginCallback'"
            class="fixed top-0"
            @showImpersonateBar="showImpersonateBar = true"
            @showRestrictionsBar="showRestrictionsBar = true"
          />

          <div id="page-subnav" class="lg:ml-[50px] sticky h-screen top-0 border-gray-200" />
          <ResizablePanelGroup direction="horizontal">
            <ResizablePanel>
              <div class="flex flex-1 flex-col justify-between z-0">
                <main class="flex flex-col h-[calc(100vh-35px)] overflow-y-auto">
                  <div class="min-h-full">
                    <router-view />
                  </div>
                </main>
                <OgFooter />
              </div>
            </ResizablePanel>
            <ResizableHandle :withHandle="route.name === 'projectsProjectDataEntry'" />
            <ResizablePanel
              v-show="!isSidePanelHidden && route.name === 'projectsProjectDataEntry'"
              id="sidepanel"
              :collapsedSize="10"
              :defaultSize="25"
              :collapsible="true"
              :maxSize="50"
              :minSize="10"
              @collapse="isSidePanelHidden = true"
              @expand="isSidePanelHidden = false"
            />
          </ResizablePanelGroup>
        </div>
      </div>
    </div>
  </template>
  <OgFetchCategoryTranslations v-if="currentUser" />
  <SpeedInsights />
</template>
